.question-choice__action {
  border: none;
  background: none;
  cursor: pointer;
  color: #d0d2d3;
  transition: color ease-out 100ms;
  font-size: x-large;

  &:hover {
    color: #000000;
  }
}

.question-choice__action--selected {
  color: #0072bc;
}
