.navbar-dropdown {
  max-height: 20rem;
  overflow: scroll;
}

.logout-button:hover {
  background-color: #f5f5f5;
  color: #0a0a0a;
  cursor: pointer;
}
