.cardFooter {
  padding: 0.5rem;
}

.buttons {
  margin-left: 3rem;
}

.input {
  max-width: 650px !important;
}
