.survey-builder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.survey-item--hidden {
  opacity: 0.5;
}

.survey-builder__version-select {
  width: 350px;
}

.survey-builder__action-buttons {
  display: flex !important;
  align-items: flex-end;
}

.survey-builder__version-select-label {
  min-width: 175px;
}

.survey-builder__version-select-help {
  display: flex;
  align-items: center;
  font-size: 1.25rem !important;
}

.b-checkbox.checkbox input[type='checkbox']:checked + .check {
  background: #0072bc
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center !important;
  border-color: #0072bc !important;
}

.b-checkbox.checkbox:hover input[type='checkbox']:not(:disabled) + .check {
  border-color: #0072bc !important;
}
