.update-button {
  margin: 1rem 1.5rem;
}

.wave-select {
  font-weight: normal;
  width: 50%;
}

.empty-hierarchies,
.empty-waves {
  opacity: 0.75;
  font-weight: bold;
}
