.footer {
  justify-content: space-between !important;
}

.modal-content {
  overflow: unset !important;
}

.modal-body {
  overflow: unset !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
