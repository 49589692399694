.number {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.choice-options {
  display: flex;
  align-items: center;
  
  @media only screen and (max-width: 577px) {
    margin-left: 60px;
    margin-top: 5px;
  }
}
