.modal {
  align-items: flex !important;
}

.modal-card {
  height: 90%;
  width: 80vw !important;
  max-height: none !important;
  margin: 0 !important;
  border-radius: 5px;
}

.modal-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-title {
  margin-bottom: 0 !important;
}

.modal-header {
  width: 100% !important;
}

.information {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 2rem;
}

@media screen and (max-width: 768px) {
  .modal-card {
    height: 90%;
    min-height: fit-content;
  }
}
