.cardFooter {
  padding: 0.5rem;
}

.buttons {
  margin-left: 3rem;
}

.input {
  max-width: 40.625rem !important;
}

.affected-data {
  max-height: 20rem;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .data-container {
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0;
    background: #f5f5f5;
    border-radius: 0.2rem;
    padding: 0.5rem;

    .data {
      font-size: 1rem;
      margin: 0.1rem 0;

      .title {
        font-weight: 600;
      }
    }
  }
}

.organization-select {
  display: inline-flex;
  align-items: center;
  max-width: 43rem !important;
  width: 100%;

  .organization-select-container {
    width: 100%;

    .select {
      z-index: 10;
      width: 100%;
    }
  }

  .information-outline {
    font-size: 1.5rem;
    margin-left: 0.1rem;
    color: #d3d3d3;
  }

  .disable-icon {
    cursor: not-allowed;
  }

  .icon-interactions {
    &:hover {
      color: #1b1a1a;
      cursor: pointer;
    }

    &:active {
      color: #d3d3d3;
    }
  }
}

.tooltip-width {
  max-width: 95%;
}
