.buttons-container {
  margin-top: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .active-checkbox {
    margin-left: 0.5rem;
  }

  .active {
    margin-bottom: 0.25rem !important;
  }
}
