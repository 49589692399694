.page-count {
  margin-right: 0.5rem;
  font-weight: 700;
}

.container {
  padding: 0.5rem;
  transition: background-color 350ms ease-out;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: 768px) {
      .action-bar-button {
        visibility: visible;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.container--hidden {
  opacity: 0.5;
}

.label {
  display: flex;
  align-items: center;
}

.action-bar {
  justify-content: center !important;

  @media only screen and (min-width: 1024px) {
    justify-content: flex-end !important;
  }
}

.action-bar-button {
  @media only screen and (min-width: 768px) {
    transition: visibility 100ms ease-out;
    visibility: hidden;
  }
}

.question-group-tag {
  margin-right: 0.25rem;
}

.opacity-50 {
  opacity: 0.5;
}

.choices-title {
  margin: 1rem -1.25em 0.5rem;
  font-weight: 600;
}