$selected-blue: #1872bc;
$error: #f14668;
$white: #ffffff;
$dark-gray: #363636;
$soft-gray: #dbdbdb;
$text-darker-gray: #4a4a4a;
$text-gray: #7a7a7a;
$border-gray: #b5b5b5;
$disabled-text-gray: #363636;
$background-light-gray: #e5e5e5;
$table-row-background: #fafafa;
$table-row-background-hover: #f5f5f5;


:export {
  selectedBlue: $selected-blue;
  backgroundLightGray: $background-light-gray;
  error: $error;
  white: $white;
}