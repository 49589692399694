@use 'styles/_colors' as colors;

.react-datepicker__header {
  background: none;
}

.react-datepicker__input-container {
  input {
    background-color: colors.$white;
    border-radius: 4px;
    color: colors.$dark-gray;
    border: 1px solid colors.$soft-gray;
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    width: 100%;
  }

  input:hover {
    border-color: colors.$border-gray;
  }
}

.react-datepicker {
  font-family: inherit;
  background-color: colors.$white;
  border-radius: 4px;
  color: colors.$dark-gray;
  border-color: colors.$soft-gray;
}

.react-datepicker__day {
  color: colors.$text-darker-gray;
}

.react-datepicker__day-name {
  color: colors.$text-gray;
  font-weight: 600;
}

.react-datepicker__current-month {
  color: colors.$dark-gray;
  font-weight: 400;
}

.react-datepicker__header {
  border-bottom: 1px solid colors.$soft-gray;
}

.react-datepicker__day--selected {
  color: colors.$white;
}

.react-datepicker__day--disabled {
  color: colors.$soft-gray !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
