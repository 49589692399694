@use 'styles/_colors' as colors;

.table-header {
  position: sticky;
  background: colors.$white;
  z-index: 1;
  top: 0;
}

.table-header-row {
  background-color: rgba(0, 0, 0, 0.1);
}

.header-title {
  text-align: center !important;
}

.row-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.row-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.first-column {
  width: 20%;
  vertical-align: middle;
  padding: 5px;
}

.choice-name {
  vertical-align: middle;
  text-align: center !important;
  padding: 5px;
}

.mobile-choices-container {
  display: flex;
  flex-direction: column;

  > p {
    margin-bottom: 0.5rem;
  }

}

.question {
  vertical-align: middle;
  padding: 3px;
}

.choice-input {
  vertical-align: middle;
  text-align: center !important;
  padding: 1rem;
  cursor: pointer;
}

.required-asterisk {
  color: colors.$error;
  margin-left: 0.5rem;
}

.required-checkbox {
  color: colors.$error;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.checkbox-error {
  border: 2px solid colors.$error !important;
}

.question-content {
  word-break: break-word;
}

.mobile-question-content {
  font-size: 1.125rem;
}

.choice-container {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
}

.choice-label {
  margin-left: 0 !important;
  margin-bottom: 0.75rem;

  &:first-child {
    margin-top: 0.75rem;
  }

  &:last-child {
    margin-bottom: 1.5rem;
  }
}

.table {
  table-layout: fixed;
}
