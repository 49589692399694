.survey {
  min-height: 100vh;
  background: #ccc;

  h1,
  h2,
  h3 {
    color: #0072bc;
  }
}
