.modal-card-divided-body {
  display: flex;
  min-height: fit-content;
}

@media screen and (max-width: 1024px) {
  .modal-card-divided-body {
    display: block;
  }
}
