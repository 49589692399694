.tag-container {
  flex-wrap: nowrap !important;
  width: 100%;

  .tag-name {
    max-width: 100%;
    overflow: scroll;
    display: flex;
    justify-content: flex-start !important;
  }

  .delete-button {
    padding: 0 0.75em !important;
  }
}
