
.field-horizontal {
  max-width: 100%;
}

.delete-field {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: fit-content;
  margin: 0.5rem;
  margin-right: 0rem;
}

.delete-button {
  display: flex;
}

.field-body-select {
  width: 90%;
  margin-right: .75rem;
  flex-direction: column;
  gap: 1rem;
  
  .field {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .field {
    max-width: 100%;
    min-width: 10rem;
  }

  .field-body-select {
    width: 100%;
    margin-right: 0;
  }

  .delete-field {
    justify-content: center;
    max-width: 100%;
    margin: 1rem;
  }
}