.status-success {
  padding: 0.25rem 1rem;
  background-color: #23d160;
  border-radius: 1rem;
  color: white;
  font-weight: 600;
}

.status-failed {
  padding: 0.25rem 1rem;
  background-color: #ff3860;
  border-radius: 1rem;
  color: white;
  font-weight: 600;
}

.icon {
  margin-right: 0.25rem;
}

.width {
  max-width: 500px;
}

.date {
  width: 100%;
}
