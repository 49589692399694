.translation {
  margin-bottom: 0 !important;
}

.translate {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  padding: 0;
  font-size: 100%;
  background: none;

  &:hover {
    color: #363636;
  }
}

.actions {
  @media only screen and (max-width: 768px) {
    justify-content: start !important;
  }
}
