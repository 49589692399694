.conditions {
  width: 60%;
  border-right: 1px solid lightgray;
  height: fit-content;
  min-height: 100%;
}

.operator {
  margin: 1rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .conditions {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid lightgray;
    min-height: 0;
  }

  .conditions-content {
    text-align: center;
  }
}