.banner {
  background-image: url(https://admin-one.justboil.me/img/trianglify-bw-50@2x.37c358f2.png);
  background-size: cover;
}

.avatar {
  height: 10rem;
  width: 10rem;
  margin-right: 1.5rem;
  border-radius: 290486px;
}
