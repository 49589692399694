.deployment-table .is-image-cell .image img {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: 1407px) {
  .deployment-table .table.has-mobile-cards thead {
    display: none;
  }

  .deployment-table .table.has-mobile-cards tfoot th {
    border: 0;
    display: inherit;
  }

  .deployment-table .table.has-mobile-cards tr {
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    max-width: 100%;
    position: relative;
    display: block;
  }

  .deployment-table.table.has-mobile-cards tr td {
    border: 0;
    display: inherit;
  }

  .deployment-table .table.has-mobile-cards tr td:last-child {
    border-bottom: 0;
  }

  .deployment-table .table.has-mobile-cards tr:not(:last-child) {
    margin-bottom: 1rem;
  }

  .deployment-table .table.has-mobile-cards tr:not([class*='is-']) {
    background: inherit;
  }

  .deployment-table .table.has-mobile-cards tr:not([class*='is-']):hover {
    background-color: inherit;
  }

  .deployment-table .table.has-mobile-cards tr.detail {
    margin-top: -1rem;
  }

  .deployment-table
    .table.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
  }

  .deployment-table
    .table.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td:before {
    content: attr(data-label);
    font-weight: 600;
    padding-right: 0.5em;
    text-align: left;
  }

  .b-table .table.has-mobile-cards td.has-no-head-mobile.is-image-cell .image {
    height: 25vw !important;
  }
}

.pagination-link {
  background-color: white;
}

.table.is-hoverable.is-striped
  tbody
  tr:not(.is-selected):hover:nth-child(even) {
  background-color: #fafafa;
}

.table-header .input {
  max-width: 250px;
  margin-left: 15px;
}
