.question-choice {
  margin-bottom: 0.75rem !important;

  .question-choice__icons {
    display: flex;
    align-items: center;
  }

  .question-choice__icons--displaying {
    width: 7.29375rem;
  }

  .question-choice__action {
    border: none;
    background: none;
    cursor: pointer;
    color: #d0d2d3;
    transition: color ease-out 100ms;

    &:hover {
      color: #000000;
    }
  }

  .question-choice__action--hidden {
    display: none;
  }

  .question-choice__drag-icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility ease-out 100ms, opacity ease-out 100ms;
  }

  .question-choice__drag-icon--dragging {
    visibility: visible;
    opacity: 1;
  }

  .question-choice__drag-icon--hidden {
    visibility: hidden;
    opacity: 0;
  }

  .question-choice__choice {
    display: flex;
    align-items: center;
  }

  .question-choice__content--displaying {
    word-break: break-word;
  }

  .question-choice__action--selected {
    color: #0072bc;
  }

  .question-choice__tags {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .question-choice__tag {
    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }

  &:hover {
    .question-choice__drag-icon {
      visibility: visible;
      opacity: 1;
    }

    .question-choice__drag-icon--hidden {
      visibility: hidden;
      opacity: 0;
    }

    .question-choice__drag-icon--being-dragged-over {
      visibility: hidden;
      opacity: 0;
    }
  }

  .icon {
    height: 100% !important;
    font-size: 24px;
  }
}

.question-choice--hidden {
  opacity: 0.5;
}
