.information {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 2rem;
}

.modal-card-empty-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
