.columns {
  max-width: 100%;
}

.column {
  max-width: 100%;
}

.container {
  flex-grow: 1;
}

.field {
  max-width: 100%;
  width: 100%;
  min-width: 4rem;
}

.field-choices {
  max-width: 81%;
  min-width: 10rem;
}

.choices-field-body {
  max-width: 100%;
}

.delete-field {
  display: flex;
  justify-content: center;
}

.delete-button {
  display: flex;
  margin: 0.5rem;
  margin-right: 0rem;
}

.label {
  align-self: center;
  margin-right: 1rem;
}

.label-choices {
  margin-right: 1.8rem;
}

.mobile-choices {
  display: none;
}

@media screen and (max-width: 768px) {
  .field {
    max-width: 100%;
    min-width: 10rem;
  }

  .field-choices {
    max-width: 100%;
    min-width: 10rem;
  }

  .delete-field {
    justify-content: center;
    max-width: 100%;
    margin: 1rem;
    justify-content: center;
  }

  .label {
    text-align: start;
    margin-right: 0rem;
  }

  .mobile-choices {
    display: block;
    max-width: 100%;
    min-width: 10rem;
  }

  .show-choices {
    display: none;
  }
}
