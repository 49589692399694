.item-container {
  padding: 0.5rem;
  transition: background-color 350ms ease-out;
  border-radius: 4px;

  .item-action-bar {
    justify-content: center !important;

    @media only screen and (min-width: 1024px) {
      justify-content: flex-end !important;
    }
  }

  .item-action-bar__button {
    @media only screen and (min-width: 768px) {
      transition: visibility 100ms ease-out;
      visibility: hidden;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: 768px) {
      .item-action-bar__button {
        visibility: visible;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.is-required {
  background-color: transparent !important;
  color: red !important;
  border: 1px solid red;
  margin-left: 0.5rem;
}

.DraftEditor-root {
  position: inherit;
}

.DraftEditor-editorContainer {
  position: inherit;
}
