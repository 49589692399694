.pagination-link.is-current {
  background-color: #0072bc !important;
  border-color: #0072bc !important;
}

.button.is-info:hover,
.button.is-info.is-hovered {
  background-color: #0072bc !important;
}

.button.is-info {
  background-color: #0072bc !important;
  color: white !important;
}
