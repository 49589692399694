.choice-options {
  @media only screen and (max-width: 577px) {
    margin-left: 60px;
    margin-top: 5px;
  }
}

.question-choice-container {
  flex-wrap: wrap;
}

.choice-field {
  @media only screen and (max-width: 320px) {
    margin-right: 0 !important;
  }
}

.hidden {
  visibility: hidden;
}
