.actions {
  width: 40%;
  margin-left: 1rem;
}

@media screen and (max-width: 1024px) {
  .actions {
    width: 100%;
    margin: 0rem;
  }

  .actions-content {
    text-align: center;
  }
}
