table {
  width: 100%;
}

.isSortable:hover,
.isCurrentSort {
  border-color: #7a7a7a !important;
}

.tableIcon {
  margin-left: 0.5rem;
}

.level {
  padding: 20px;
}

.stats {
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
}
