.survey-details__status-tag {
  width: 4rem;
}

.deployment-attribute__title--mobile {
  display: none !important;
}

.column {
  flex-basis: 0;
}

@media screen and (max-width: 768px) {
  .deployment-attribute__title--desktop {
    display: none !important;
  }

  .deployment-attribute__title--mobile {
    display: inline !important;
  }

  .deployment-atribute__container {
    display: flex !important;
    justify-content: space-between;
  }
}