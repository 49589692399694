.card-header {
  color: #363636;
  display: flex;
  font-weight: 700;
  padding: 0.75rem;
  flex-wrap: wrap;

  div {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;

    input {
      margin-left: 1rem;
    }

    @media only screen and (max-width: 768px) {
      margin: 0.5rem 0;
    }
  }
}

.actions {
  min-width: 70px;
}

.table-name {
  word-break: break-word;
  text-align: left;
}
