@use 'styles/_colors' as colors;

.deployments-container {
  overflow-x: scroll;
}

.row {
  >th {
    text-wrap: nowrap;
    background-color: colors.$table-row-background;

    &:last-child {
      position: sticky;
      right: 0;
    }
  }

  >td {
    text-wrap: pretty;

    &:last-child {
      position: sticky;
      right: 0;
    }
  }

  &.is-selected {
    >td:last-child {
      background-color: colors.$table-row-background;
    }
  }

  &:not(.is-selected):nth-child(even) {
    >td:last-child {
      background-color: colors.$table-row-background;
    }

    &:hover {
      >td:last-child {
        background-color: colors.$table-row-background-hover;
      }
    }
  }

  &:not(.is-selected):nth-child(odd) {
    >td:last-child {
      background-color: colors.$white;
    }

    &:hover {
      >td:last-child {
        background-color: colors.$table-row-background;
      }

      >td:last-child {
        background-color: colors.$table-row-background;
      }
    }
  }
}

.trigger-analytics-data-tip {
  padding: 0 !important;
  border: none !important;
  height: auto !important;
  width: auto !important;

  .trigger-analytics {
    margin: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .deployments-container {
    padding-right: 0 !important;
  }
}