@use 'styles/_colors' as colors;

.checkbox {
  margin-left: 0.5rem;
}

.choice-order-container {
  font-size: 1.5rem !important;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.choice-order-selected {
  color: colors.$selected-blue;
}

.cursor-pointer {
  cursor: pointer;
}

.create-dataset {
  margin: 0.5rem 0 1.25rem;
}

.hidden-mobile {
  @media only screen and (max-width: 540px) {
    display: none;
  }
}

.hidden-desktop {
  @media only screen and (min-width: 541px) {
    display: none;
  }
}

.tag-weight {
  @media only screen and (max-width: 540px) {
    flex: 1;
  }
}

.create-tag-fields {
  display: flex;
  align-items: center;
}

.create-tag-text {
  margin-right: 1rem;
}

.tag-types-select {
  flex: 1;
}

.create-tag-button {
  margin-bottom: 0.75rem;
}

.tag-name {
  min-width: 71px;
}

.create-tag-button-text {
  min-width: 47px;
  display: flex;
  justify-content: center;
}

.tags {
  width: 100%;
}

.slider-inputs-container {
  display: flex;
  gap: 1rem;
}

.error {
  border: 1px solid colors.$error !important;
}

.text-error {
  color: colors.$error;
}

.error-message {
  display: inline-block;
  line-height: 0.5rem;
  font-size: 0.625rem;
}

.inputs-label {
  flex-basis: 25%;
}
