.question-modal {
  overflow: visible;
  min-height: 375px;

  .question-modal__content {
    width: 90% !important;

    @media only screen and (min-width: 768px) {
      width: 75% !important;
    }

    @media only screen and (min-width: 1024px) {
      width: 60% !important;
    }
  }

  .question-modal__body {
    min-height: 350px;
  }

  .question-type__type-select {
    min-width: 190px;
  }

  .question-choices__title {
    display: flex;
    align-items: center;
  }

  .question-choices__data-set-select {
    min-width: 250px;
  }
}
