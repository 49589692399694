.title {
  display: flex;
  align-items: center;
}

.data-set-select {
  min-width: 250px;
  display: block;
}

.spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.first-dataset-title {
  margin-top: 0 !important;
}

.dataset-title {
  font-weight: 600;
  margin: 1rem 0 0.25rem;
}