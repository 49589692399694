@media only screen and (max-width: 768px) {
  .border-bottom {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem !important;
  }
}

.deployment-action {
  margin-right: 0.5rem;
}

.actions {
  min-width: 86px;
}
