.subject {
  width: 35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.criteria {
  width: 30%;
}

.choices {
  width: 35%;
}

.conditions-div {
  display: flex;
  margin: 1rem 0rem;
  text-align: center;
}

.actions-div {
  margin: 1rem 0rem;
  display: grid;
}

.edit-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action {
  font-weight: bold;
}

.td {
  border-color: lightgray !important;
  vertical-align: middle !important;
}

.operator {
  text-align: center;
  font-weight: bold;
}

.action-subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ul {
  margin-top: 0px !important;
  margin-left: 1rem !important;
}

.li {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  list-style: inside;
}

.button {
  display: contents !important;
}

@media screen and (max-width: 768px) {
  .conditions-div {
    display: block;
  }

  .subject {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .criteria {
    width: 100%;
  }

  .choices {
    width: 100%;
  }
}
