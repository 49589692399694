.modal-card-head {
  width: 100%;
}

.footer {
  justify-content: space-between !important;
}

.content-without-header {
  border-radius: 6px 6px 0 0;
}