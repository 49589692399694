@use 'styles/_colors' as colors;

.chart-container {
  display: flex;
}

.column-container {
  display: flex;
  flex-direction: column;
  padding: 0.3rem 1rem 1rem 1rem;
  background-color: colors.$background-light-gray;
  border: 1px solid gray;
  margin: 0.4rem 0;
}

.dragging {
  background-color: darkgray;
}

.flex-column {
  flex-direction: column;
}

.column-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}

.delete-icon {
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: #df4d60;
  }
}

.add-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 0.25rem;

  &:hover {
    color: #33d1b2;
  }
}

.drag-icon {
  font-size: 25px;
  margin-right: 0.25rem;

  &:hover {
    color: black;
  }
}

.column-title {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.storage-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin: 0.4rem 0 0.25rem 0;
}

.storage-icon {
  margin-right: 0.25rem;
}
