.modal {
    align-items: flex-end !important;
}

.modal-card {
    height: 100%;
    max-height: none !important;
    margin: 0 !important;
}

.modal-title {
    margin-bottom: 0 !important;
}

.modal-header {
    width: 100% !important;
}