.th {
  border-color: lightgray !important;
  background-color: #f8f8f8;
}

.edit {
  width: 5%;
  border-color: lightgray !important;
  background-color: #f8f8f8;
}

.table {
  table-layout: fixed;
}

.select {
  width: 30%;
  margin-left: 1rem;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .select {
    width: 100%;
  }

  .edit {
    width: 20%;
    border-color: lightgray !important;
    background-color: #f8f8f8;
  }
}
