.card-footer {
  justify-content: center;
  background-color: #f5f5f5 !important;
  transition: background-color 250ms linear;
  border: none;
  cursor: pointer;

  &:hover:enabled {
    background-color: white !important;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
