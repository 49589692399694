.field-label-shrink {
  flex-grow: initial !important;
  white-space: nowrap;
}
.algolia-filter {
  .is-check {
    flex-grow: initial !important;

    .field-body {
      padding-top: 0.575em;
    }

    .field-label {
      margin-right: 0rem;
    }
  }

  .field-label {
    flex-grow: initial !important;
    margin: 0rem 1rem;
    white-space: nowrap;
  }

  .field-body {
    flex-grow: 2;
  }
}
