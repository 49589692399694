.chart {
  border: 2px solid grey;
  padding: 0.75rem;
  width: 100%;
  font-weight: 700;
  background-color: #9be3d5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.dragging {
  background-color: #33d1b2;
}

.my-2 {
  margin: 0rem 0.5rem;
}

.mx-2 {
  margin: 0.5rem 0rem;
}

.chart-in-storage {
  background-color: #e2e76c;
}
