.toolbar {
  padding: 10px 6px 4px;
  border-radius: 5px 5px 0 0;
  border: 1px solid lightgray;
  border-bottom: 0;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 0;
  -webkit-user-select: none;
  user-select: none;
}

.editor {
  min-height: 70px;
  border-radius: 0 0 5px 5px;
  resize: vertical;
  background-color: white;
  border: 1px solid lightgray;
  padding: 0 0.75rem 0.75rem 0.75rem;
  cursor: text;
}
