.tag-select {
  min-width: 150px;
}

.checkbox {
  margin-left: 0.5rem;
}

.disabled {
  pointer-events: none;
}
