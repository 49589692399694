.header {
  background-color: #f5f5f5 !important;
}

.item {
  flex-shrink: 1 !important;
}

.icon {
  height: 5rem;
  width: 5rem;
  font-size: 48px;
}
