@use 'styles/_colors' as colors;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-block {
  width: 100%;
  padding: 1rem 2rem;
  border: 1px solid colors.$text-gray;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prevent-backtracking-block {
  display: flex;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
}