.cardFooter {
  padding: 0.5rem;
}

.select {
  margin-right: 0.5rem;
  width: 20%;
  align-self: center;
  min-width: 7rem;
  z-index: 10;
}

.input {
  max-width: 50%;
}
