@use 'styles/_colors' as colors;

.field {
  flex-direction: column;
  margin-left: 1.5rem;
  margin-top: 1rem;
}

.question-content {
  display: flex;
  align-items: center;
}

.required-asterisk {
  color: colors.$error;
  margin-left: 0.5rem;
}

.required-checkbox {
  color: colors.$error;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.checkbox-error {
  border: 2px solid colors.$error !important;
}

.radio-error {
  border: 2px solid colors.$error !important;
}

.text-area-error {
  border: 1px solid colors.$error !important;
}

.dropdown {
  width: 100%;

  @media only screen and (min-width: 1024px) {
    max-width: 25%;
  }
}

.question-choice {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.question-choice__content {
  display: flex !important;
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}

.open-ended-input {
  max-width: 250px !important;
}

.order {
  min-width: 115px;
  margin-bottom: 0.5rem !important;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.rank-question {
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    text-align: center;
  }
}

.control {
  margin-right: 0 !important;
}

.question-choice {
  word-break: break-word;
}

.slider {
  margin: 1rem 1.5rem 0 0;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}
