.create-dataset-button {
  min-width: 47px;
  display: flex;
  justify-content: center;
}

.select-width {
  @media only screen and (min-width: 768px) {
    max-width: 50%;
  }
}
